import React from 'react'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import LoadingButton from '@mui/lab/LoadingButton'
import Divider from '@mui/material/Divider'
import Hidden from '@mui/material/Hidden'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Fields, { Field, useFieldsAPI } from '@oi/react/components/fields'
import { LogoColorIcon } from '@oi/react/components/icons'
import IllustrationContainer from '@oi/react/components/illustration-container'
import { useLoginMutation } from '@oi/react/modules/api'
import { LanguageSwitcher } from '@oi/react/modules/language'

import type { Login } from '@oi/react/modules/api'

import { useRedirectUserWhenLoggedIn } from '../auth.hook'
import ForgotPasswordDialog from './forgot-password-dialog'
import LoginInfoSide from './info-side'
import ResetPasswordDialog from './reset-password-dialog'

export default function LoginRoute() {
  const fieldsAPI = useFieldsAPI<Pick<Login, 'username' | 'password'>>()
  const intl = useIntl()

  const { handleNavigate } = useRedirectUserWhenLoggedIn()

  const [login, loginMutation] = useLoginMutation()

  const [showForgotPasswordDialog, setShowForgotPasswordDialog] = React.useState(false)

  const handleLoginClick = React.useCallback(async () => {
    const { isValid, values } = await fieldsAPI.getValues()

    if (isValid && values) {
      const { error } = await login({
        login: {
          client_id: 'oi-app',
          // rememberComputer: false,
          ...values
        }
      })

      if (!error) {
        handleNavigate()
      }
    }

  }, [handleNavigate, login, fieldsAPI])

  return (
    <IllustrationContainer illustration={<LoginInfoSide />}>
      {showForgotPasswordDialog && (
        <ForgotPasswordDialog
          setOpen={setShowForgotPasswordDialog} />
      )}

      <ResetPasswordDialog />

      <Stack
        alignItems={'center'}
        display={'flex'}
        flexGrow={1}
        gap={6}
        justifyContent={{
          md: 'center',
          xs: 'start'
        }}>
        <Hidden mdUp>
          <Stack
            mb={2}
            width={'100%'}>
            <LogoColorIcon />
          </Stack>
        </Hidden>
        <div>
          <Typography
            textAlign={'center'}
            variant={'h1'}
            gutterBottom>
            <FormattedMessage
              defaultMessage={'Login to Online Incasso'}
              id={'routes.login.title'} />
          </Typography>

          <Typography
            textAlign={'center'}
            variant={'body1'}>
            <FormattedMessage
              defaultMessage={'Welcome back to your own collection agency.'}
              id={'routes.login.subtitle'} />
          </Typography>
        </div>

        <Stack
          width={'100%'}
          maxWidth={{
            md: 500
          }}>
          <Fields
            api={fieldsAPI}
            error={loginMutation.error}
            onEnter={handleLoginClick}
            errorMessages={defineMessages({
              'api.unknown-combination': {
                id: 'api.unknown-combination',
                defaultMessage: 'Unknown combination!'
              },
              'api.account-locked': {
                id: 'api.account-locked',
                defaultMessage: 'The user you want to log in with is blocked because of the number of failed login attempts.'
              }
            })}>
            <Field.Text
              autoComplete={'username'}
              name={'username'}
              label={intl.formatMessage({
                id: 'routes.login.username',
                defaultMessage: 'Username'
              })}
              required />

            <Field.Text
              autoComplete={'current-password'}
              name={'password'}
              type={'password'}
              label={intl.formatMessage({
                id: 'routes.login.password',
                defaultMessage: 'Password'
              })}
              required />
          </Fields>

          <Stack
            alignItems={'center'}
            direction={'row'}
            display={'flex'}
            justifyContent={'space-between'}
            mt={2}>
            <Link
              color={'secondary'}
              onClick={() => setShowForgotPasswordDialog(true)}>
              <Typography
                color={'inherit'}
                variant={'caption'}>
                <FormattedMessage
                  defaultMessage={'Forgot password?'}
                  id={'btn.forgot-password'} />
              </Typography>
            </Link>

            <Stack
              direction={'row'}
              gap={1}>
              <LanguageSwitcher />

              <LoadingButton
                loading={loginMutation.loading}
                onClick={handleLoginClick}
                variant={'contained'}>
                <FormattedMessage
                  defaultMessage={'Login'}
                  id={'btn.login'} />
              </LoadingButton>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <Divider sx={{ my: 2 }} />

      <Stack
        pb={5}
        textAlign={'center'}>
        <Typography variant={'body1'}>
          <FormattedMessage
            defaultMessage={'No account yet?'}
            id={'routes.login.sign-up'} />
          &nbsp;
          <Link
            href={'/register'}
            underline={'always'}>
            <FormattedMessage
              defaultMessage={'Register'}
              id={'btn.register'} />
          </Link>
        </Typography>
      </Stack>

    </IllustrationContainer>
  )
}
