import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSearchParams } from 'react-router-dom'
import LoadingButton from '@mui/lab/LoadingButton'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Fields, { Field, useFieldsAPI } from '@oi/react/components/fields'
import { CloseLineIcon } from '@oi/react/components/icons'
import { ResetPassword, useResetPasswordMutation } from '@oi/react/modules/api'

export default function ResetPasswordDialog() {
  const intl = useIntl()

  const [searchParams, setSearchParams] = useSearchParams()
  const resetCode = searchParams.get('resetCode')

  const fieldsAPI = useFieldsAPI<ResetPassword>()
  const [resetPassword, forgotPasswordMutation] = useResetPasswordMutation()

  const handleResetPasswordClick = React.useCallback(async () => {
    const { isValid, values } = await fieldsAPI.getValues()

    if (isValid && resetCode) {
      const { error } = await resetPassword({
        resetPassword: {
          resetCode,
          password: values.password,
          passwordCheck: values.passwordCheck
        }
      })

      if (!error) {
        setSearchParams({})
      }
    }
  }, [fieldsAPI, resetCode, resetPassword, setSearchParams])

  return (
    <Dialog open={!!resetCode}>
      <DialogTitle>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}>
          <FormattedMessage
            defaultMessage={'Reset password'}
            id={'dialog.reset-password.title'} />

          <IconButton
            color={'secondary'}
            onClick={() => setSearchParams({})}>
            <CloseLineIcon />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          <FormattedMessage
            defaultMessage={'Enter a new password below to change your password.'}
            id={'dialog.reset-password.text'} />
        </DialogContentText>

        <Fields
          api={fieldsAPI}
          disabled={forgotPasswordMutation.loading}
          error={forgotPasswordMutation.error}
          onEnter={handleResetPasswordClick}>
          <Field.Text
            autoComplete={'password'}
            name={'password'}
            type={'password'}
            label={intl.formatMessage({
              id: 'routes.login.password',
              defaultMessage: 'Password'
            })}
            validations={{
              score: true
            }}
            required />

          <Field.Text
            autoComplete={'password-repeat'}
            name={'passwordCheck'}
            type={'password'}
            label={intl.formatMessage({
              id: 'dialog.label.password-repeat',
              defaultMessage: 'Repeat password'
            })}
            validations={{
              sameAs: {
                field: 'password',
                fieldLabel: intl.formatMessage({
                  id: 'routes.login.password',
                  defaultMessage: 'Password'
                })
              }
            }}
            required />

        </Fields>
      </DialogContent>

      <DialogActions>
        <LoadingButton
          loading={forgotPasswordMutation.loading}
          onClick={handleResetPasswordClick}
          variant={'contained'}>
          <FormattedMessage
            defaultMessage={'Send'}
            id={'btn.send'} />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
