import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { generateUtilityClasses } from '@mui/base/generateUtilityClasses'
import Container from '@mui/material/Container'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import { LogoColorIcon } from '@oi/react/components/icons'
import { useLogoutMutation } from '@oi/react/modules/api'
import posthog from 'posthog-js'

import HeaderDrawer from './header-drawer.component'
import HeaderMenu from './header-menu.component'
import HeaderMenuButton from './header-menu-button/header-menu-button.component'
import HeaderNav from './header-nav.component'

export const headerClasses = generateUtilityClasses('Header', ['logo'])

export const StyledToolbar = styled(Toolbar, {
  shouldForwardProp: (prop) => prop !== 'isOpen'
})<{ isOpen?: boolean }>(({ theme, isOpen }) => ({
  position: 'sticky',
  top: 0,
  boxShadow: '0px -4px 16px 0px rgba(0, 0, 0, 0.1)',
  padding: '0px !important',
  zIndex: theme.zIndex.appBar,
  backgroundColor: theme.palette.background.default,
  minHeight: 64,
  maxHeight: 64,

  [theme.breakpoints.up('md')]: {
    minHeight: 97,
    maxHeight: 97
  },

  [theme.breakpoints.down('md')]: {
    [`& .${headerClasses.logo}`]: {
      height: 32,

      '& svg': {
        height: 'inherit',
        width: 'inherit'
      }
    },
    transition: 'background-color 0.2s',
    ...(isOpen && {
      backgroundColor: theme.palette.secondary.dark
    })
  }
}))

export const StyledToolbarContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(6)
}))

export default function Header() {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false)
  const location = useLocation()
  const navigate = useNavigate()

  const [logout] = useLogoutMutation()

  const handleDrawerClose = React.useCallback(() => {
    setIsDrawerOpen(false)
  }, [])

  const handleDrawerToggle = React.useCallback(() => {
    setIsDrawerOpen(!isDrawerOpen)
  }, [isDrawerOpen])

  const handleLogoutClick = React.useCallback(async () => {
    await logout(null)
    navigate('/login')

    posthog.reset()
  }, [logout, navigate])

  React.useEffect(() => {
    if (isDrawerOpen) {
      handleDrawerClose()
    }
  }, [location.pathname])

  return (
    <StyledToolbar isOpen={isDrawerOpen}>
      <StyledToolbarContainer>
        <Stack
          direction={'row'}
          flexGrow={1}
          justifyContent={'space-between'}>
          <Stack
            alignItems={'center'}
            direction={'row'}
            gap={{ xs: 1, md: 5 }}>
            <Link
              className={headerClasses.logo}
              href={'/'}>
              <LogoColorIcon invertColors={isDrawerOpen} />
            </Link>

            <HeaderNav />
          </Stack>

          <Stack
            alignItems={'center'}
            direction={'row'}
            gap={1}>
            <HeaderMenu logout={handleLogoutClick} />

            <HeaderMenuButton
              isOpen={isDrawerOpen}
              onClick={handleDrawerToggle}
            />
          </Stack>
        </Stack>
      </StyledToolbarContainer>

      <HeaderDrawer
        isOpen={isDrawerOpen}
        logout={handleLogoutClick}
        onClose={handleDrawerClose} />
    </StyledToolbar>
  )
}
