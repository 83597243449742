import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import FullScreenLoader from '@oi/react/components/full-screen-loader'
import { useOnMountEffect } from '@oi/react/modules/utils'
import { useStorage } from '@oi/react/utils/dom'

export default function AuthorizeRoute() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const [_, setOauthToken] = useStorage<string | undefined>('oauth-token', undefined, true)

  useOnMountEffect(() => {
    if (searchParams.has('oauth_token')) {
      setOauthToken(searchParams.get('oauth_token') as string)
    }

    navigate('/', { replace: true })
  })

  return <FullScreenLoader />
}
