import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Fields, { Field, useFieldsAPI } from '@oi/react/components/fields'
import { CloseLineIcon } from '@oi/react/components/icons'
import { useForgotPasswordMutation } from '@oi/react/modules/api'

import type { ForgotPassword } from '@oi/react/modules/api'

interface ForgotPasswordProps {
  setOpen: (open: boolean) => void
}

export default function ForgotPasswordDialog({ setOpen }: ForgotPasswordProps) {
  const intl = useIntl()

  const [formSubmitted, setFormSubmitted] = React.useState<boolean>(false)

  const fieldsAPI = useFieldsAPI<ForgotPassword>()
  const [forgotPassword, forgotPasswordMutation] = useForgotPasswordMutation()

  const handleForgotPasswordClick = React.useCallback(async () => {
    const { isValid, values } = await fieldsAPI.getValues()

    if (isValid && values) {
      const { error } = await forgotPassword({
        forgotPassword: values
      })

      if (!error) {
        setFormSubmitted(true)
      }
    }
  }, [forgotPassword, fieldsAPI])

  return (
    <Dialog
      onClose={() => setOpen(false)}
      open={true}>
      <DialogTitle>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}>
          <FormattedMessage
            defaultMessage={'Forgot password'}
            id={'dialog.forgot-password.title'} />

          <IconButton
            color={'secondary'}
            onClick={() => setOpen(false)}>
            <CloseLineIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {formSubmitted && (
            <FormattedMessage
              defaultMessage={'If an account exists with this email address, you will receive a link to reset your password.'}
              id={'dialog.forgot-password.success-text'} />
          )}

          {!formSubmitted && (
            <FormattedMessage
              defaultMessage={'Enter your username and you will receive a link to set a new password within minutes.'}
              id={'dialog.forgot-password.text'} />
          )}
        </DialogContentText>

        {!formSubmitted && (
          <Fields
            api={fieldsAPI}
            error={forgotPasswordMutation.error}
            onEnter={handleForgotPasswordClick}>
            <Field.Text
              autoComplete={'username'}
              name={'username'}
              label={intl.formatMessage({
                id: 'routes.login.username',
                defaultMessage: 'Username'
              })}
              required />
          </Fields>
        )}
      </DialogContent>

      <DialogActions>
        {formSubmitted && (
          <Button
            onClick={() => setOpen(false)}
            variant={'contained'}>
            <FormattedMessage
              defaultMessage={'Close'}
              id={'btn.close'} />
          </Button>
        )}

        {!formSubmitted && (
          <LoadingButton
            loading={forgotPasswordMutation.loading}
            onClick={handleForgotPasswordClick}
            variant={'contained'}>
            <FormattedMessage
              defaultMessage={'Send'}
              id={'btn.send'} />
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  )
}
