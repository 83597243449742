import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Hidden from '@mui/material/Hidden'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography, { typographyClasses } from '@mui/material/Typography'
import Fields, { Field, fieldClasses, useFieldsAPI } from '@oi/react/components/fields'
import { LogoColorIcon, RegisterIcon } from '@oi/react/components/icons'
import { Register, useRegisterMutation } from '@oi/react/modules/api'
import { LanguageSwitcher } from '@oi/react/modules/language'
import posthog from 'posthog-js'

import type { RegisterProps } from '../register.component'

import { useRedirectUserWhenLoggedIn } from '../../auth.hook'
import { useRegisterSubtitle } from './register-form.hook'

interface RegisterDto extends Register {
  privacyConditions: boolean
  privacyStatement: boolean
}

interface RegisterFormProps extends RegisterProps {
  toggleIllustrationVisibility: () => void
}

const StyledStackWrapper = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',

  [theme.breakpoints.down('md')]: {
    alignItems: 'start',
    justifyContent: 'start'
  },

  [`& .${typographyClasses.root}`]: {
    width: '100%'
  }
}))

const StyledStackForm = styled(Stack)(({ theme }) => ({
  width: '100%',
  maxWidth: 500,

  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',

    [`& .${fieldClasses.groupContainer}`]: {
      gridTemplateColumns: 'initial'
    }
  }
}))

const StyledOpenTutorialButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  borderRadius: theme.shape.borderRadius,
  flex: 1
}))

export default function RegisterForm({ type, toggleIllustrationVisibility }: RegisterFormProps) {
  const fieldsAPI = useFieldsAPI<RegisterDto>()
  const navigate = useNavigate()
  const intl = useIntl()

  useRedirectUserWhenLoggedIn()

  const [register, mutation] = useRegisterMutation()

  const handleRegisterClick = React.useCallback(async () => {
    const { isValid, values } = await fieldsAPI.getValues()

    if (isValid && values) {
      const { error } = await register({
        register: values
      })

      if (!error) {
        posthog.capture('user:signup')
        navigate('/')
      }
    }
  }, [fieldsAPI, navigate, register])

  const subtitle = useRegisterSubtitle(type)

  return (
    <>
      <StyledStackWrapper
        alignItems={'center'}
        display={'flex'}
        flexGrow={1}
        justifyContent={'center'}>
        <Hidden mdUp>
          <Stack mb={6}>
            <LogoColorIcon />
          </Stack>
        </Hidden>

        <Typography
          textAlign={'center'}
          variant={'h1'}
          gutterBottom>
          <FormattedMessage
            defaultMessage={'Create an account'}
            id={'routes.register.title'} />
        </Typography>

        <Typography
          mb={6}
          textAlign={'center'}
          variant={'body1'}>
          {subtitle}
        </Typography>

        <StyledStackForm>

          <Fields
            api={fieldsAPI}
            error={mutation.error}
            onEnter={handleRegisterClick}
            errorMessages={defineMessages({
              'api.register.username-exists': {
                id: 'api.register.username-exists',
                defaultMessage: 'Email already in use!'
              }
            })}>
            <Field.Text
              name={'name'}
              label={intl.formatMessage({
                id: 'user.name',
                defaultMessage: 'Name'
              })}
              required
            />

            <Field.Text
              name={'companyName'}
              label={intl.formatMessage({
                id: 'user.company-name',
                defaultMessage: 'Company name'
              })}
              required
            />

            <Field.Country
              name={'countryCode'}
              label={intl.formatMessage({
                id: 'company.country',
                defaultMessage: 'Country'
              })}
              required />

            <Field.Text
              name={'username'}
              label={intl.formatMessage({
                id: 'user.email',
                defaultMessage: 'Email'
              })}
              validations={{
                email: true
              }}
              required
            />

            <Field.Text
              name={'phoneNumber'}
              label={intl.formatMessage({
                id: 'user.phone-number',
                defaultMessage: 'Phone number'
              })}
              validations={{
                phone: {
                  countryCodeField: 'countryCode'
                }
              }}
              required
            />

            <Field.Group name={'register.password'}>
              <Field.Text
                name={'password'}
                type={'password'}
                label={intl.formatMessage({
                  id: 'routes.register.password',
                  defaultMessage: 'Password'
                })}
                validations={{
                  score: true
                }}
                required
              />
              <Field.Text
                name={'passwordCheck'}
                type={'password'}
                label={intl.formatMessage({
                  id: 'routes.register.password-repeat',
                  defaultMessage: 'Repeat password'
                })}
                validations={{
                  sameAs: {
                    field: 'password',
                    fieldLabel: intl.formatMessage({
                      id: 'routes.register.password',
                      defaultMessage: 'Password'
                    })
                  }
                }}
                required
              />
            </Field.Group>

            <Stack>
              <Field.Checkbox
                name={'newsletter'}
                label={intl.formatMessage({
                  id: 'user.accept.newsletter',
                  defaultMessage: 'Yes, I would like to receive updates about Online Incasso'
                })}
              />

              <Field.Checkbox
                name={'privacyConditions'}
                label={intl.formatMessage({
                  id: 'user.accept.privacy-conditions',
                  defaultMessage: 'Yes, I agree to the <a>general delivery and privacy conditions</a>'
                }, {
                  // @ts-expect-error something weird
                  a: (parts) => (
                    <Link
                      href={'https://app.onlineincasso.nl/docs/Algemene%20leverings%20en%20privacyvoorwaarden%20Online%20Incasso%20v2022.pdf'}
                      target={'_blank'}
                      underline={'always'}>
                      {parts}
                    </Link>
                  )
                })}
                required
              />

              <Field.Checkbox
                name={'privacyStatement'}
                label={intl.formatMessage({
                  id: 'user.accept.privacy-statement',
                  defaultMessage: 'Yes, I agree with the <a>privacy statement</a>'
                }, {
                  // @ts-expect-error something weird
                  a: (parts) => (
                    <Link
                      href={'https://app.onlineincasso.nl/docs/Privacyverklaring%20Online%20Incasso%20v2024.pdf'}
                      target={'_blank'}
                      underline={'always'}>
                      {parts}
                    </Link>
                  )
                })}
                required
              />

              <Field.Text
                name={'captcha'}
                hidden
                required />

              <Stack mt={2}>
                <ReCAPTCHA
                  onChange={(value) => fieldsAPI.form.setValue('captcha', value || '')}
                  sitekey={'6LeEBkUhAAAAAKWc5e7bZGJKCAimYe2ZGlCQy_vl'}
                />
              </Stack>
            </Stack>
          </Fields>

          <Stack
            alignItems={'end'}
            display={'flex'}
            gap={3}
            mt={5}>
            <Stack
              direction={'row'}
              gap={1}>
              <LanguageSwitcher />
              <LoadingButton
                onClick={handleRegisterClick}
                variant={'contained'}>
                <FormattedMessage
                  defaultMessage={'Register'}
                  id={'btn.register'} />
              </LoadingButton>
            </Stack>

            <Hidden mdUp>
              <Stack
                direction={'row'}
                gap={2}
                mt={2}
                width={'100%'}>
                <RegisterIcon
                  height={58}
                  width={58} />

                <StyledOpenTutorialButton
                  color={'secondary'}
                  onClick={toggleIllustrationVisibility}
                  variant={'contained'}>
                  <FormattedMessage
                    defaultMessage={'How does it work?'}
                    id={'btn.how-does-it-work'} />
                </StyledOpenTutorialButton>
              </Stack>
            </Hidden>
          </Stack>
        </StyledStackForm>
      </StyledStackWrapper>

      <Divider sx={{ my: 2 }} />

      <Stack
        pb={5}
        textAlign={'center'}>
        <Typography variant={'body1'}>
          <FormattedMessage
            defaultMessage={'Do you have an account?'}
            id={'routes.register.login'} />
          &nbsp;
          <Link
            href={'/login'}
            underline={'always'}>
            <FormattedMessage
              defaultMessage={'Login'}
              id={'btn.login'} />
          </Link>
        </Typography>
      </Stack>
    </>
  )
}
