import React from 'react'
import { FormattedMessage } from 'react-intl'
import Button from '@mui/material/Button'
import ButtonBase from '@mui/material/ButtonBase'
import Hidden from '@mui/material/Hidden'
import Stack from '@mui/material/Stack'
import { lighten, styled } from '@mui/material/styles'
import Typography, { typographyClasses } from '@mui/material/Typography'
import Footer from '@oi/react/components/footer'
import { LogoColorIcon, RegisterIcon } from '@oi/react/components/icons'

import AuthBottom from '../../auth-bottom.component'
import { RegisterProps } from '../register.component'
import { useRegistrationBottomPoints } from './register-info-side-bottom.hook'
import { useRegistrationTooltipPoints } from './register-info-side-tooltip.hook'

interface RegisterInfoSide extends RegisterProps {
  toggleIllustrationVisibility: () => void
}

const StyledStack = styled(Stack)({
  backgroundColor: '#F5F5FF',
  height: '100%'
})

const StyledPointContainerStack = styled(Stack)(({ theme }) => ({
  position: 'relative',
  zIndex: 3,
  padding: theme.spacing(10),
  justifyContent: 'center',

  [theme.breakpoints.down('md')]: {
    justifyContent: 'start',
    padding: theme.spacing(4, 4, 0),
    alignItems: 'center'
  },

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3)
  }
}))

const StyledPointStack = styled(Stack)(({ theme }) => ({
  position: 'relative',
  zIndex: 3,
  backgroundColor: theme.palette.primary.main,
  padding: theme.spacing(2),
  minHeight: 110,
  minWidth: 280,
  width: '60%',
  [`& .${typographyClasses.root}`]: {
    color: theme.palette.common.white
  },
  borderRadius: theme.shape.borderRadius
}))

const RoundButton = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== 'active'
})<{
  active: boolean
}>(({ theme, active }) => ({
  position: 'relative',
  zIndex: 3,
  height: 32,
  width: 32,
  borderRadius: 32,
  backgroundColor: lighten(theme.palette.primary.main, 0.5),
  ...(active && {
    backgroundColor: theme.palette.primary.main
  }),
  [`& .${typographyClasses.root}`]: {
    color: theme.palette.common.white
  }
}))

const StyledIconContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  zIndex: 1,
  bottom: '10%',
  right: '10%',

  [theme.breakpoints.down('xl')]: {
    bottom: '8%',
    right: '8%'
  },

  [theme.breakpoints.down('lg')]: {
    bottom: '5%',
    right: '5%'
  },

  [theme.breakpoints.down('md')]: {
    position: 'relative',
    bottom: 0,
    right: 0
  }
}))

const StyledCloseTutorialButton = styled(Button)({
  height: 48
})

export default function RegisterInfoSide({ type, toggleIllustrationVisibility }: RegisterInfoSide) {
  const [currTooltipPoint, setCurrTooltipPoint] = React.useState(1)

  const tooltipPoints = useRegistrationTooltipPoints(type)
  const bottomPoints = useRegistrationBottomPoints(type)

  const handlePointClick = React.useCallback((key: number) => () => {
    setCurrTooltipPoint(key)
  }, [])

  const { order, title, description } = tooltipPoints[currTooltipPoint]

  return (
    <StyledStack
      display={'flex'}
      flexGrow={1}
      justifyContent={'space-between'}>
      <StyledPointContainerStack
        display={'flex'}
        flex={1}
        gap={6}>

        <Hidden mdUp>
          <Stack
            width={'100%'}>
            <LogoColorIcon />
          </Stack>
        </Hidden>
        <Typography
          textAlign={'center'}
          variant={'h1'}
          width={'100%'}
          gutterBottom>
          <FormattedMessage
            defaultMessage={'How does it work?'}
            id={'routes.register.info.title'} />
        </Typography>
        <Stack
          gap={2}
          alignItems={{
            md: 'start',
            xs: 'center'
          }}>
          <StyledPointStack
            direction={'row'}
            gap={1}>
            <Stack>
              <Typography
                fontWeight={600}
                variant={'body1'}>
                {order}
              </Typography>

            </Stack>
            <Stack
              gap={1}
              minHeight={155}>
              <Typography
                fontWeight={600}
                variant={'body1'}>
                {title}
              </Typography>
              <Typography
                variant={'body1'}>
                {description}
              </Typography>
            </Stack>
          </StyledPointStack>
          <Stack
            direction={'row'}
            gap={1}>
            {Object.keys(tooltipPoints).map((key) => (
              <RoundButton
                key={key}
                active={parseInt(key) === currTooltipPoint}
                onClick={handlePointClick(parseInt(key))}>
                <Typography
                  fontWeight={600}
                  variant={'body1'}>
                  {key}
                </Typography>
              </RoundButton>
            ))}
          </Stack>
        </Stack>
        <StyledIconContainer>
          <RegisterIcon />
        </StyledIconContainer>
        <Hidden mdUp>
          <StyledCloseTutorialButton
            color={'secondary'}
            onClick={toggleIllustrationVisibility}
            variant={'contained'}
            fullWidth>
            <FormattedMessage
              defaultMessage={'Back'}
              id={'btn.back'} />
          </StyledCloseTutorialButton>
        </Hidden>
      </StyledPointContainerStack>
      <Stack>
        <Hidden mdDown>
          <AuthBottom
            points={bottomPoints}
            variant={'secondary'}
          />
          <Footer />
        </Hidden>
      </Stack>
    </StyledStack>
  )
}
