import React from 'react'

export type Storage<Data> = [Data | undefined, (value: Data) => void]

/**
 * Returns the value from storage once, does not update it afterward
 */
export function useStorage<Data>(key: string, defaultValue?: Data, session?: boolean): Storage<Data> {
  const storageKey = `___oi.${key}`

  const handleSaveToStorage = React.useCallback((value: Data) => {
    if (value) {
      (session ? sessionStorage : localStorage).setItem(storageKey, JSON.stringify(value))
    } else {
      (session ? sessionStorage : localStorage).removeItem(storageKey)
    }
  }, [storageKey, session])

  const valueFromStorage = React.useMemo((): Data | undefined => {
    const value = (session ? sessionStorage : localStorage).getItem(storageKey)

    if (value) {
      return JSON.parse(value)
    }

    return defaultValue
  }, [storageKey, session, defaultValue])

  return [valueFromStorage, handleSaveToStorage]
}
