import React from 'react'
import ReactDOM from 'react-dom/client'
import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import ErrorBoundary from '@oi/react/components/error-boundary'
import Spotlight from '@oi/react/components/spotlight'
import { beforeSend } from '@oi/react/modules/analytics'
import { queryClient } from '@oi/react/modules/api'
import LanguageProvider, { Locale } from '@oi/react/modules/language'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import posthog from 'posthog-js'

import packageJson from '../package.json'
import { loadMessages } from './modules/languages.utils'
import theme from './modules/theme'
import AppRoutes from './routes/routes.component'
import supportedLocales from './translations/locales.json'

posthog.init(
  'phc_KXPzL94BlObEx1yCqgnQ0K4uzYLHR1eoHUAu1pEuiKU',
  {
    api_host: 'https://eu.posthog.com',
    capture_pageview: false,
    disable_session_recording: true,
    // Prevents full events before the user is logged-in/signed-up
    person_profiles: 'identified_only',
    before_send: beforeSend
  }
)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

// Cache for the ltr version of the styles
const cacheLtr = createCache({
  key: 'css',
  prepend: true
})
cacheLtr.compat = true

root.render(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  <React.StrictMode>
    <MuiThemeProvider theme={theme}>
      <CacheProvider value={cacheLtr}>
        <CssBaseline />

        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools
            buttonPosition={'bottom-right'}
            initialIsOpen={false} />

          <LanguageProvider
            loadMessages={loadMessages}
            supportedLocales={supportedLocales as Locale[]}>
            <ErrorBoundary
              apiKey={'AIzaSyAQRE7ucvt5V2DIv6EO6_HubvWKi1AIa_I'}
              projectId={'online-incasso'}
              service={packageJson.name}
              version={packageJson.version}
              withGoToDashboard>
              <Spotlight>
                <AppRoutes />
              </Spotlight>
            </ErrorBoundary>
          </LanguageProvider>
        </QueryClientProvider>
      </CacheProvider>
    </MuiThemeProvider>
  </React.StrictMode>
)
